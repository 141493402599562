import s2 from "s2-engine";

import { IS_NODE } from "@/__main__/constants.mjs";
import modalTemplate from "@/search/modal-template.mjs";
import omniSearchView from "@/search/modal-view.mjs";
import globals from "@/util/global-whitelist.mjs";

s2.shouldUnmountRoot = false;
const [_proxy, fragment] = !IS_NODE ? s2(omniSearchView, modalTemplate) : [];

let container;
if (fragment) {
  container = globals.document.createElement("div");
  container.id = "omni-search-modal";
  container.appendChild(fragment);
}

export default container;
